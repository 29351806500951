import React, { useEffect } from "react";
import Error from "next/error";
import cx from "classnames";
import { getStaticPage, queries } from "@data";
import { useRouter } from "next/router";
import { useUpdateSiteTheme } from "@lib/context";
import Layout from "@components/layout";
import { Module } from "@components/modules";
const Home = ({ data }) => {
  const { site, page } = data;
  const updateSiteTheme = useUpdateSiteTheme();
  useEffect(() => {
    updateSiteTheme(false);
  }, []);
  if (!page) {
    return (
      <Error
        title={`"Home Page" is not set in Sanity, or the page data is missing`}
        statusCode="Data Error"
      />
    );
  }

  return (
    <Layout site={site} page={page}>
      {page.modules?.map((module, key) => (
        <Module key={key} index={key} module={module} />
      ))}
    </Layout>
  );
};

export async function getStaticProps({ preview, previewData }) {
  const pageData = await getStaticPage(
    `
    *[_type == "page" && _id == ${queries.homeID}] | order(_updatedAt desc)[0]{
      
      modules[]{
        ${queries.modules}
      },
      title,
      seo
    }
  `,
    {
      active: preview,
      token: previewData?.token,
    }
  );

  return {
    props: {
      data: pageData,
    },
  };
}

export default Home;
